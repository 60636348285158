<template>
  <div class="video-more">
    <h :isback="true">
      <span class="name">
        最新视频
      </span>
    </h>
    <ul class="options-wrap">
      <li>
        <div class="name">资费：</div>
        <div class="types">
          <cube-button
            v-for="(item, index) in member"
            :key="item"
            :class="index == roleStatus ? 'active' : ''"
            @click="changeRole(index)"
          >
            {{ item }}
          </cube-button>
        </div>
      </li>
      <li>
        <div class="name">类型：</div>
        <div class="types">
          <cube-button
            v-for="(item, index) in typeList"
            :key="item"
            :class="index == lableType && roleStatus != 3 ? 'active' : ''"
            :disabled="roleStatus == 3"
            @click="changeType(index)"
          >
            {{ item }}
          </cube-button>
        </div>
      </li>
      <li>
        <div class="name">年代：</div>
        <div class="types">
          <cube-button
            v-for="(item, index) in yearList"
            :key="item"
            :class="index == lableYears && roleStatus != 3 ? 'active' : ''"
            :disabled="roleStatus == 3"
            @click="changeYear(index)"
          >
            {{ item }}
          </cube-button>
        </div>
      </li>
    </ul>
    <div class="result-wrap">
      <cube-scroll
        ref="scroll"
        :data="result"
        :options="options"
        @pulling-down="onPullingDown"
        @pulling-up="onPullingUp"
      >
        <preview :list="result" />
      </cube-scroll>
    </div>
  </div>
</template>
<script>
import h from "@/components/base/header";
import preview from "@/components/video/preview";
import { getVideoCategory } from "@/api/video";
export default {
  components: {
    h,
    preview
  },
  data() {
    return {
      member: ["全部", "免费", "会员", "金币"],
      typeList: ["全部", "常规桌", "锦标赛", "普通"],
      yearList: ["全部", "2020", "2019", "2018", "2017"],
      roleStatus: this.$route.query.roleStatus
        ? this.$route.query.roleStatus
        : 0,
      lableType: this.$route.query.lableType ? this.$route.query.lableType : 0,
      lableYears: 0,
      result: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      options: {
        click: true,
        pullDownRefresh: {
          threshold: 60,
          // stop: 44,
          stopTime: 1000,
          txt: "更新成功"
        },
        pullUpLoad: {
          threshold: 0,
          stopTime: 1000,
          txt: "数据加载成功"
        }
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const role = this.roleStatus == 0 ? null : this.roleStatus;
      const type = this.lableType == 0 ? null : parseInt(this.lableType);
      const year = this.lableYears == 0 ? null : this.yearList[this.lableYears];
      const params = {
        roleStatus: role,
        lableType: type,
        lableYears: year,
        clientType: 3,
        querySubVideo: 0,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber
      };
      getVideoCategory(params).then(res => {
        this.total = res.object.total;
        if (this.pageNumber == 1) {
          this.result = res.object.videoList;
        } else {
          this.result = this.result.concat(res.object.videoList);
        }
      });
    },
    onPullingDown() {
      this.pageNumber = 1;
      this.getList();
    },
    onPullingUp() {
      if (this.pageNumber >= Math.ceil(this.total / this.pageSize)) {
        this.$refs.scroll.forceUpdate();
        return;
      }
      this.pageNumber++;
      this.getList();
    },
    changeRole(inx) {
      this.roleStatus = inx;
      if (this.roleStatus == 3) {
        this.lableType = 0;
        this.lableYears = 0;
      }
      this.pageNumber = 1;
      this.getList();
    },
    changeType(inx) {
      this.lableType = inx;
      this.pageNumber = 1;
      this.getList();
    },
    changeYear(inx) {
      this.lableYears = inx;
      this.pageNumber = 1;
      this.getList();
    }
  }
};
</script>
<style lang="stylus" scoped>
.video-more
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #e9eced
  z-index 10
  .options-wrap
    height 104px
    padding 9px 16px
    background #fff
    overflow hidden
    li
      display flex
      margin-bottom 18px
      &:last-child
        margin-bottom 0
      .name
        margin-right 16px
        font-size 10px
        color #6B6B6B
      .types
        flex 1
        .cube-btn
          display inline-block
          width 49px
          height 17px
          line-height 17px
          margin-right 10px
          padding 0
          border 1px solid #E4E2E2
          border-radius 8px
          font-size 10px
          &:last-of-type
            margin-right 0
          &.active
            background #1E88E4
            border-color #1E88E4
            color #fff
  .result-wrap
    position relative
    height calc(100vh - 140px)
    padding 16px 0
    overflow hidden
</style>
